export default ($axios: any) => (resource: string): any => {
  const api: any = {
    create(payload: any) {
      return $axios.post(resource, payload);
    },
    createFormData(payload: any) {
      return $axios.post(resource, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
    },
    index(payload: any) {
      return $axios.get(`${resource}`, {
        params: payload,
      });
    },
    show(payload: any) {
      return $axios.get(`${resource}/${payload.id}`);
    },
    update(payload: any) {
      return $axios.put(`${resource}/${payload.id}`, payload);
    },
    delete(id: string) {
      return $axios.delete(`${resource}/${id}`);
    },
  };
  if (resource.indexOf('blogs') > 0) {
    api.PostBlogLike = (payload: any) => $axios.post(`${resource}/like`, payload);
    api.PostBlogUnLike = (payload: any) => $axios.post(`${resource}/unlike`, payload);
  }
  if (resource.indexOf('users') > 0) {
    api.GetUserAll = (payload: any) => $axios.get(`${resource}/getAllUser`, payload);
    api.PostUserLogin = (payload: any) => $axios.post(`${resource}/signin`, payload);
    api.PostUserSignout = (payload: any) => $axios.post(`${resource}/signout`, payload);
    api.PostUserSignup = (payload: any) => $axios.post(`${resource}/signup`, payload);
    api.PostForgetPwd = (payload: any) => $axios.post(`${resource}/forgetPwd`, payload);
    api.PostChangePwd = (payload: any) => $axios.post(`${resource}/changePwd`, payload);
  }
  if (resource.indexOf('role') > 0) {
    api.getAllRole = (payload: any) => $axios.get(`${resource}/getRoleList`, payload);
  }
  if (resource.indexOf('menu') > 0) {
    api.getAllMenuTree = (payload: any) => $axios.get(`${resource}/tree`, payload);
  }
  if (resource.indexOf('menus') > 0) {
    console.log('🚀 ~ file: createApiReposity.ts ~ line 47 ~ menus', resource);
    api.synchronizeMockData = (payload: any) => {
      console.log('🚀 ~ file: createApiReposity.ts ~ line 48 ~ payload', payload);
      return $axios.get(`${resource}/async`, {
        params: payload,
      });
    };
  }
  return api;
};
