import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { MyLocalStorage } from '@/utils/storage';
import apiRepositories, { repositories } from './api-repositories';
import { login } from '@/utils';

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' : '/',
  auth: {
    username: '',
    password: '',
  },
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    put: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  },
  // 是否跨站点访问控制请求
  withCredentials: true,
  timeout: 30000,
  // timeout: 5000,
  transformRequest: [
    (data: any, headers) => {
      if (headers['Content-Type'] != 'application/x-www-form-urlencoded;charset=UTF-8') {
        data = JSON.stringify(data);
      }
      return data;
    },
  ],
  validateStatus() {
    // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
    return true;
  },
  // transformResponse: [
  //   (data: any) => {
  //     // if (typeof data === 'string' && data.startsWith('{')) {
  //     //   data = JSON.parse(data);
  //     // }
  //     return data;
  //   },
  // ],
});

// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.auth.username = MyLocalStorage.get('token');
    return config;
  },
  (error: any) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '服务器异常，请联系管理员！';
    return Promise.resolve(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const status = response.status;

    switch (status) {
      case 200:
        return response.data;
      case 403:
        // cookie 与 token 不一致，重新拿 cookie 换 token
        repositories.token.index().then(res => {
          MyLocalStorage.set('userInfo', res);
          MyLocalStorage.set('token', res.token);
        });

        //TODO: 无权限也是403
        return Promise.reject(response);
      case 412:
      case 400:
        login();
        break;
      default:
        return Promise.reject(response);
    }
  },
  (error: any) => {
    console.log(JSON.stringify(error), '1111');

    error.data = {
      msg: '服务器异常，请检查网络或联系管理员！',
    };

    if (error.code === 'ECONNABORTED') {
      error.data.msg = '请求超时，请检查网络或联系管理员！';
    }
    // 错误抛到业务代码
    return Promise.reject(error);
  }
);

export default service;
