import axios from './axios';

import createApiReposity from './createApiReposity';

const repositoryWithAxios = createApiReposity(axios);

export const repositories = {
  // blogs: repositoryWithAxios('/api/blogs'),
  token: repositoryWithAxios('/api/v1/token'),
  users: repositoryWithAxios('/api/v1/users'),
  role: repositoryWithAxios('/api/v1/role'),
  menu: repositoryWithAxios('/api/v1/menu'),
  categories: repositoryWithAxios('/api/v1/categories'),
  // tags: repositoryWithAxios('/api/tags'),
  resourceTypes: repositoryWithAxios('/api/v1/resourceTypes'),
  resources: repositoryWithAxios('/api/v1/resources'),
  // comments: repositoryWithAxios('/api/comments'),
  replys: repositoryWithAxios('/api/v1/replys'),

  erpLogin: repositoryWithAxios('/jdapi/User/erplogin'), // 借用http://j-api.jd.com/的erp登录接口
  userInfo: repositoryWithAxios('/jdapi/User/getUserInfo'), // 借用http://j-api.jd.com/的获取用户信息接口
  logout: repositoryWithAxios('/jdapi/User/logout'), // 借用http://j-api.jd.com/的退出登录接口

  articles: repositoryWithAxios('/api/v1/articles'), // 文章
  articlesCount: repositoryWithAxios('/api/v1/articles/count'), // 文章数量
  tags: repositoryWithAxios('/api/v1/tags'), // 文章标签
  comments: repositoryWithAxios('/api/v1/comments'), // 评论

  upload: repositoryWithAxios('/api/v1/upload'), // 上传

  categoryMenu: repositoryWithAxios('/api/v1/category/menus'),
};

export default {
  install(Vue: any) {
    Vue.prototype.$myApi = repositories;
  },
};
