import Vue from 'vue';
import Vuex from 'vuex';

import { TState, TAction } from './type';
import { repositories as $myApi } from '@/api/api-repositories';

Vue.use(Vuex);

const state = (): TState => ({
  categoryList: [],
  tagList: [],
  blogResult: {},
  showSignInModal: false,
  showSignUpModal: false,
  userInfo: null,
  token: '',
  highLightIndex: 0, // markdown 目录高亮索引
  categoryIndex: 0,
  cacheArticleData: null,
});

const getters = {
  getBlogResult: (state: TState) => state.blogResult,
  getCategoryList: (state: TState) => state.categoryList,
  getCategoryIdByValue: (state: TState) => (value: number) => {
    let categoryId = '';
    if (value) {
      const categoryIdFilter = state.categoryList.filter(item => item.value === value);
      if (categoryIdFilter.length) {
        categoryId = categoryIdFilter[0]._id;
      } else {
        categoryId = '';
      }
    } else {
      categoryId = '';
    }
    return categoryId;
  },
  getTagList: (state: TState) => state.tagList,
  getIsShowSignInModal: (state: TState) => state.showSignInModal,
  getIsShowSignUpModal: (state: TState) => state.showSignUpModal,
  getUserInfo: (state: TState) => state.userInfo,
  getToken: (state: TState) => state.token,
  getHighLightIndex: (state: TState) => state.highLightIndex,
  getCategoryIndex: (state: TState) => state.categoryIndex,
  getCacheArticleData: (state: TState) => state.cacheArticleData,
};

const mutations = {
  setCatgoryList(state: TState, data: any) {
    state.categoryList = data;
  },
  setTagList(state: TState, data: any) {
    state.tagList = data;
  },
  setBlogResult(state: TState, data: any) {
    state.blogResult = data;
  },
  setSignInModal(state: TState, isShow: boolean) {
    state.showSignInModal = isShow;
  },
  setSignUpModal(state: TState, isShow: boolean) {
    state.showSignUpModal = isShow;
  },
  setUserInfo(state: TState, data: any) {
    state.userInfo = data;
  },
  setToken(state: TState, data: any) {
    state.token = data;
  },
  setHighLightIndex(state: TState, { index }: { index: number }) {
    state.highLightIndex = index;
  },
  setCatgoryIndex(state: TState, data: any) {
    state.categoryIndex = data;
  },
  setCacheArticleData(state: TState, data: any) {
    state.cacheArticleData = data;
  },
};

const actions: TAction = {
  async requestCategoryList({ commit }) {
    const res = await $myApi.categories.index({
      page: 1,
      pageSize: 100,
    });
    commit('setCatgoryList', res?.data?.result || []);
  },
  async requestTagList({ commit }) {
    const res = await $myApi.tags.index({
      page: 1,
      pageSize: 100,
    });
    commit('setTagList', res?.data?.result || []);
  },
  toggleSignInModal({ commit }, isShow: boolean) {
    commit('setSignInModal', isShow);
  },
  toggleSignUpModal({ commit }, isShow) {
    commit('setSignUpModal', isShow);
  },
  changeUserInfo({ commit }, data) {
    commit('setUserInfo', data);
  },
  changeToken({ commit }, data) {
    commit('setToken', data);
  },
  changeHighLightIndex({ commit }, data) {
    commit('setHighLightIndex', data);
  },
  changeCategoryIndex({ commit }, data) {
    commit('setCatgoryIndex', data);
  },
  cacheArticleData({ commit }, data) {
    commit('setCacheArticleData', data);
  },
};

export default new Vuex.Store({
  state: () => ({}),
  mutations: {},
  actions: {},
  modules: {
    common: {
      namespaced: true,
      state,
      getters,
      mutations,
      actions,
    },
  },
});
