export default class MyStorage {
  private storage: Storage;
  private nameSpace: string;

  constructor(type: string, space = 'blog-') {
    this.nameSpace = space;
    if (type === 'local') {
      this.storage = localStorage;
    } else {
      this.storage = sessionStorage;
    }
  }

  getKey(key: string): string {
    return this.nameSpace + key;
  }

  /**
   * @desc 获取值
   * @param key string
   */
  get(key: string) {
    const value = this.storage.getItem(this.getKey(key));
    if (value) {
      try {
        const valueJson = JSON.parse(value);
        if (typeof valueJson === 'object') {
          return valueJson;
        } else if (typeof valueJson === 'number') {
          return valueJson;
        } else {
          return value;
        }
      } catch (e) {
        return value;
      }
    } else {
      return value;
    }
  }

  /**
   * @desc 设置值
   * @param key string
   * @param value any
   */
  set(key: string, value: any) {
    this.storage.setItem(this.getKey(key), typeof value === 'object' ? JSON.stringify(value) : value);
  }

  /**
   * @desc 移除某一项
   * @param key string
   */
  remove(key: string) {
    this.storage.removeItem(this.getKey(key));
  }

  /**
   * @desc 清除所有
   */
  clear() {
    this.storage.clear();
  }

  /**
   * @desc 批量保存
   * @param data 是一个字典
   */
  setList(data: any) {
    for (const i in data) {
      this.storage.setItem(this.getKey(i), data[i]);
    }
  }

  /**
   * @desc 批量删除
   * @param list 是一个数组
   */
  removeList(list: []) {
    for (const i in list) {
      this.storage.removeItem(this.getKey(i));
    }
  }
}

export const MyLocalStorage = new MyStorage('local');

export const MySessionStorage = new MyStorage('session');
