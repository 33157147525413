import _ from 'lodash';

/**
 * @desc 把线性数据转成树形数据
 * @param data Array
 * @param idFieldName id 字段名称
 * @param pidFieldName 父 id 字段名称
 * @param childname 子 字段名称
 * @returns Array
 */
export function setTreeData(data, idFieldName = 'id', pidFieldName = 'parent_id', childname = 'children'): Array<any> {
  console.log(data);

  const cloneData = _.cloneDeep(data); // 对源数据深度克隆
  const tree = cloneData.filter(father => {
    //循环所有项
    const branchArr = cloneData.filter(child => {
      return father[idFieldName] == child[pidFieldName]; //返回每一项的子级数组
    });
    if (branchArr.length > 0) {
      father[childname] = branchArr; //如果存在子级，则给父级添加一个children属性，并赋值
    }
    return father[pidFieldName] == 0; //返回第一层
  });
  console.log(tree);

  return tree; //返回树形数据
}

/**
 * @desc 将对象转化为数组；不能保证顺序
 * @param obj key-value 对象
 * @param options 配置
 * @param options.valueType 值类型
 * @param options.valueField 值 key
 * @param options.labelField label key
 * @param options.initVal 是否需要默认值
 */
export const objToArray = (
  obj: { [key: string]: any },
  options?: {
    valueType?: 'number' | 'string';
    valueField?: string;
    labelField?: string;
    initVal?: boolean;
  }
) => {
  if (!obj) {
    return [];
  }
  const { valueType = 'number', valueField = 'value', labelField = 'label', initVal = false } = options || {};
  return Object.keys(obj).reduce<any[]>(
    (prev, cur) => {
      prev.push({
        [labelField]: obj[cur],
        [valueField]: valueType === 'number' ? Number(cur) : cur,
      } as any);
      return prev;
    },
    initVal ? [{ value: '', label: '全部' }] : []
  );
};

export function login(): void {
  // const loginUrl = 'http://test.ssa.jd.com/sso/login';
  const loginUrl = 'http://ssa.jd.com/sso/login';
  location.href = loginUrl + '?ReturnUrl=' + encodeURIComponent(location.href);
}
