import router from '@/router';
import apiRepositories, { repositories } from './api/api-repositories';
import { MyLocalStorage } from './utils/storage';

// import { logoutFun } from '@/utils/common';
// import { staticRouters } from '@/router/static-router';

router.beforeEach(async (to, from, next: any) => {
  if (to.fullPath.indexOf('admin') > -1) {
    console.log(MyLocalStorage.get('token'));
    if (MyLocalStorage.get('token')) {
      // console.log(MyLocalStorage.get('token'));
      next();
    } else {
      router.push({ path: `/login` });
      //repositories.token.index({ username: 'huangjin', password: 'huangjin0924' }).then(res => {
      //MyLocalStorage.set('userInfo', res);
      // MyLocalStorage.set('token', res.token);
      // repositories.menu.index().then(res => {
      //   console.log(res, 'menu');
      // });
      //next({ ...to, replace: true });
      // });
      // next({ ...to, replace: true });
    }
  } else {
    next();
  }

  // if (true) {
  //   next({ ...to, replace: true });
  // } else {
  //   next();
  // }
});

router.afterEach(() => {
  // console.log('xx');
});
