import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/index.vue';

const AdminLayout = () => import(/* webpackChunkName: "AdminLayout" */ '@/components/framework/admin-layout.vue');

const LoginPage = () => import(/* webpackChunkName: "LoginPage" */ '@/views/login/index.vue');

const WritePage = () => import(/* webpackChunkName: "WritePage" */ '@/views/write/index.vue');
const ArticlePage = () => import(/* webpackChunkName: "ArticlePage" */ '@/views/article/index.vue');
const CategoryPage = () => import(/* webpackChunkName: "CategoryPage" */ '@/views/category/index.vue');
const TagPage = () => import(/* webpackChunkName: "TagPage" */ '@/views/tag/index.vue');
const CommentPage = () => import(/* webpackChunkName: "CommentPage" */ '@/views/comment/index.vue');
const UserPage = () => import(/* webpackChunkName: "UserPage" */ '@/views/user/index.vue');
const RolePage = () => import(/* webpackChunkName: "RolePage" */ '@/views/role/index.vue');
const ResourcePage = () => import(/* webpackChunkName: "ResourcePage" */ '@/views/resource/index.vue');
const CategoryMenu = () => import(/* webpackChunkName: "ResourcePage" */ '@/views/categoryMenu/index.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'admin',
    component: AdminLayout,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminLayout,
    children: [
      {
        path: 'write',
        name: 'write',
        component: WritePage,
      },
      {
        path: 'article',
        name: 'article',
        component: ArticlePage,
      },
      {
        path: 'category',
        name: 'category',
        component: CategoryPage,
      },
      {
        path: 'tag',
        name: 'tag',
        component: TagPage,
      },
      {
        path: 'comment',
        name: 'comment',
        component: CommentPage,
      },
      {
        path: 'user',
        name: 'user',
        component: UserPage,
      },
      {
        path: 'role',
        name: 'role',
        component: RolePage,
      },
      {
        path: 'resource',
        name: 'resource',
        component: ResourcePage,
      },
      {
        path: 'menu',
        name: 'menu',
        component: CategoryMenu,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes,
});
export default router;
