import Vue from 'vue';
import VueCookies from 'vue-cookies';

import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/index.less'; // 演示项目样式
import './router-congrol';
import './filters';

import './plugins/element.ts';

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import '@/assets/css/sspai.less';

import apiRepositories from './api/api-repositories';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import loading from './directive/loading/loading';

Vue.directive('loading', loading);

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(apiRepositories);

Vue.use(VueCookies);

Vue.use(mavonEditor);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
